import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import GeneralProposalDetailContMain from "presentation/view/container/GeneralProposal/GeneralProposalDetailContMain";
import GeneralProposalMaintenance from "./GeneralProposalMaintenance";

export const GeneralProposalMDView: React.FC = () => {
    const [generalProposalState] = useGeneralProposalTracked();
     const isShowDetail = generalProposalState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <GeneralProposalMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><GeneralProposalDetailContMain/> </div>
    } 

    </>
}