import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { GeneralProposalProvider } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { GeneralProposalMDView } from "presentation/view/section/GeneralProposal/GeneralProposalMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const GeneralProposalContMain = () => {
    return <ANAInfoWrapper permission={Permission.GENERAL_PROPOSAL_MAINTENANCE}>
        <MessageBarWrapper>
            <GeneralProposalProvider>
                <GridStyles/>
                <GeneralProposalMDView/>
            </GeneralProposalProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default GeneralProposalContMain;