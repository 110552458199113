import { ShiftRepoImpl } from "domain/repository/Common/ShiftRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { GeneralProposalRepoImpl } from "domain/repository/GeneralProposal/GeneralProposalRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { GeneralProposalVM } from "presentation/viewModel/GeneralProposal/GeneralProposalVM";
import { useMemo } from "react";

export const useGeneralProposalVM = () => {
    const [, setGeneralProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useMemo(() =>
        GeneralProposalVM({
            dispatch: [setGeneralProposalState],
            generalProposalRepo: GeneralProposalRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
            shiftRepo: ShiftRepoImpl()
        }), [setGeneralProposalState])

    return generalProposalVM
}

