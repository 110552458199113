import { SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalTariffItemISColumnDefinition";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";


const TariffItemIsTablePanel: React.FC = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const { isShowEditPanel, currentSelectedRow, generalProposalItemIsList, generalProposalItemTierList, currentTariffItemIs } = generalProposalState;
    let gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        if ("NEW" === generalProposalState.currentSelectedRow.status || "REJECTED" === generalProposalState.currentSelectedRow.status) {
            const selectedRows = e.api.getSelectedRows();
            generalProposalVM.updateSelectedTariffItemIsRows(selectedRows);
        }
    }, [generalProposalState.currentSelectedRow, generalProposalVM])


    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice());

        // if (!generalProposalState.tariffItemIsSelectedRows ||
        //     generalProposalState.tariffItemIsSelectedRows.length <= 0) {        
        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
        // }
    })

    const handleAdd = useCallback(() => {
        if ("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status) {
            messageBarVM.showError("General Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot Add.");
            return;
        }

        if (!_.isEmpty(generalProposalItemTierList) && generalProposalItemTierList.length > 1) {
            messageBarVM.showError("Intensive surcharge does not support multiple charge tier");
            return;
        }

        generalProposalVM.onAddIs();
    }, [currentSelectedRow?.status, generalProposalItemTierList, generalProposalVM, messageBarVM]);

    const handEdit = useCallback(() => {
        if (!currentTariffItemIs || !currentTariffItemIs.shiftCode || !currentTariffItemIs.percentage) {
            messageBarVM.showError("Please select a IS record.");
            return;
        }
        generalProposalVM.onTariffItemISEidtClick();
    }, [currentTariffItemIs, generalProposalVM, messageBarVM]);

    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        if (!isShowEditPanel) {
            hdrBtns.push({
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
        }

        if (!isShowEditPanel) {
            hdrBtns.push({
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }

        return hdrBtns

    }, [isShowEditPanel])

    const handleDelete = useCallback(() => {
        if ("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status) {
            messageBarVM.showError("General Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be deleted.");
            return;
        }
        if (!currentTariffItemIs || !currentTariffItemIs.shiftCode || !currentTariffItemIs.percentage) {
            messageBarVM.showError("Please select a IS record.");
            return;
        }

        generalProposalVM.onDeleteIs();
    }, [currentTariffItemIs, currentSelectedRow?.status, generalProposalVM, messageBarVM])

    const memoGeneralProposalTariffItemTierTable = useMemo(() => {

        return (
            <NbisTable
                id='general-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice()}
                data={transferRowData(generalProposalItemIsList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handEdit}
                // onCloseButton={handClose}
                showPaginator={false}
                editable={false}
                showAddIcon={!isShowEditPanel}
                onAddClick={handleAdd}
                onDeleteButton={handleDelete}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                showUploadIcon={false}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 625px)"
                ref={gridRef}
            />
        );
    }, [generalProposalItemIsList, handEdit, handleAdd, handleDelete, handleSelectionChange, isShowEditPanel, memoBtns])

    return <><TableWrapper>
        {memoGeneralProposalTariffItemTierTable}
    </TableWrapper>
    </>;
}

export default memo(TariffItemIsTablePanel);
