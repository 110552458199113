import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffItemIsEditPanel } from "./TariffItemIsEditPanel";
import TariffItemTierTablePanel from "./TariffItemTierTablePanel";

const TariffItemRightPanel = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const { isShowEditPanel } = generalProposalState;

    const memoTariffItemTierTablePanel = useMemo(() => {
        return <TariffItemTierTablePanel />
    }, [])

    const memoTariffItemIsEditPanel = useMemo(() => {
        return <TariffItemIsEditPanel />
    }, [])

    return <>
        <div className={`main-comp-wrapper${(isShowEditPanel) ? '' : ' im-hide-side-form-draggable'}`}>

            <SliderPanel
                isOpen={true}
                draggable={true}
                leftSectionWidth={(isShowEditPanel) ? "60%" : "100%"}
                rightSectionWidth={(isShowEditPanel) ? "40%" : "0%"}
                leftChildren={memoTariffItemTierTablePanel}
                rightChildren={memoTariffItemIsEditPanel}
            />
        </div>
    </>
}

export default memo(TariffItemRightPanel);